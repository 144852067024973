import { useEffect, useRef, useState } from "react";
import { SubscriptionsViewModel } from "../../viewmodels/SubscriptionsViewModel";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import SimpleNotification from "../../components/notifications/SimpleNotification";
import SlideOver from "../../components/slideOver/SlideOver";
import FilterSubscriptionsForm from "../../components/forms/subscriptions/FilterSubscriptionsForm";
import { formatDate } from "../../shared/utility";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import EmptyModal from "../../components/modals/EmptyModal";
import SkipSubscriptionModal from "../../components/modals/SkipSubscriptionModal";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { format } from "date-fns";

import { withRouter } from "../../shared/utility";

import Pagination from "../../components/pagination/Pagination";

function Subscriptions(props) {
  const { logout } = props;

  const {
    changeSubscription,
    confirmationModal,
    emptyModalOpen,
    filter,
    getFrequencies,
    getSubscriptions,
    loading,
    openCancelSubscriptionModal,
    openSkipSubscriptionModal,
    setConfirmationModal,
    setEmptyModalOpen,
    setShowSimpleNoti,
    setSlideOver,
    setSkipSubscriptionModal,
    skipSubscriptionModal,
    showSimpleNoti,
    slideOver,
    subscriptions,
    updateFilter,
  } = SubscriptionsViewModel(logout);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getFrequencies();
    getSubscriptions(filter.startDate, filter.endDate, null, null, currentPage);
  }, []);

  const searchRef = useRef();

  const handleSearch = () => {
    getSubscriptions(null, null, null, searchRef.current.value);
  };

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
    getSubscriptions(filter.startDate, filter.endDate, null, null, newPage);
  };

  return (
    <div>
      <div className="mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <p className="text-bold">
              Showing subscriptions through{" "}
              {format(filter.startDate, "LLL dd, YYY")} -{" "}
              {format(filter.endDate, "LLL dd, YYY")}
            </p>
          </div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              {/* search starts */}
              <div className="mt-1 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by email or Zoey order id"
                    // onChange={handleSearch}
                    ref={searchRef}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {/* search ends */}
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-yellow-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                onClick={() =>
                  setSlideOver({
                    childComponent: (
                      <FilterSubscriptionsForm
                        filter={filter}
                        searchValue={searchRef.current.value}
                        setOpen={setSlideOver}
                        updateFilter={updateFilter}
                      />
                    ),
                    open: true,
                  })
                }
              >
                Filter
              </button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            SKU
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Customer Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Upcoming Delivery Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {subscriptions.data.length > 0
                          ? subscriptions.data.map((product, index) => (
                              <tr key={product.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <a
                                    href={
                                      "https://fridge-filters.ca/" +
                                      product.zoeyUrl
                                    }
                                    className="hover:underline"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {product.name} ({product.frequency_name}) -
                                    ${product.price}
                                  </a>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <a
                                    href={`/subscribers/${product.subscriber_zoey_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {product.customer_name}
                                  </a>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {parseInt(product.amount)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {formatDate(product.next_date)}
                                  {product.delivery_status === "6" ? (
                                    <p className="text-red-600">Skipped</p>
                                  ) : null}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {product.deliveryStatus} -{" "}
                                  {product.subscriptionStatus}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <button
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() =>
                                      changeSubscription(product, index)
                                    }
                                    disabled={product.delivery_status === "6"}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500 ml-3"
                                    onClick={() =>
                                      openSkipSubscriptionModal(product, index)
                                    }
                                    disabled={product.delivery_status === "6"}
                                  >
                                    Skip
                                  </button>
                                  <button
                                    className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ml-3"
                                    onClick={() =>
                                      openCancelSubscriptionModal(
                                        product,
                                        index
                                      )
                                    }
                                    disabled={product.delivery_status === "6"}
                                  >
                                    Cancel
                                  </button>
                                </td>
                              </tr>
                            ))
                          : "no data"}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between bg-white px-4 py-3 mt-5 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              {subscriptions.prev_page_url ? (
                <a
                  href={subscriptions.prev_page_url}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </a>
              ) : null}
              {subscriptions.next_page_url ? (
                <a
                  href={subscriptions.next_page_url}
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </a>
              ) : null}
            </div>
            <div className="flex items-center justify-between bg-white px-4 py-3 mt-5 sm:px-6">
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="mr-5">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">{subscriptions.from}</span> to{" "}
                    <span className="font-medium">{subscriptions.to}</span> of{" "}
                    <span className="font-medium">{subscriptions.total}</span>{" "}
                    results
                  </p>
                </div>

                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={subscriptions.total}
                  pageSize={10}
                  onPageChange={(page) => handlePagination(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
      />
      <EmptyModal
        childComponent={emptyModalOpen.childComponent}
        open={emptyModalOpen.open}
        setOpen={setEmptyModalOpen}
      />
      <SimpleNotification
        notificationDetails={showSimpleNoti}
        setOpen={setShowSimpleNoti}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <SkipSubscriptionModal
        skipSubscriptionModal={skipSubscriptionModal}
        setSkipSubscriptionModal={setSkipSubscriptionModal}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
);
