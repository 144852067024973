import { useState } from "react";

import { makeGetApiCall } from "../shared/makeApiCall";

import AllAddressesForm from "../components/forms/subscriptions/AllAddressesForm";
import ChangeSubscriptionForm from "../components/forms/subscriptions/ChangeSubscriptionForm";
import CreateNewAddressForm from "../components/forms/subscriptions/CreateNewAddressForm";

import { SimpleNotificationType } from "../components/notifications/SimpleNotification";
import { makePostApiCall } from "../shared/makeApiCall";
import { updateObjectInArray } from "../shared/utility";

import { addDays, format } from "date-fns";

export function SubscriptionsViewModel(logout) {
  const [confirmationModal, setConfirmationModal] = useState({
    action: null,
    open: false,
    childComponent: null,
  });
  const [emptyModalOpen, setEmptyModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [filter, setFilter] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 90),
    dropdownValue: 0,
    searchValue: null,
  });

  const [formLoading, setFormLoading] = useState(false);
  const [frequencies, setFrequencies] = useState({ data: [] });
  const [loading, setLoading] = useState(true);
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [skipSubscriptionModal, setSkipSubscriptionModal] = useState({
    action: null,
    open: false,
    childComponent: null,
  });
  const [subscriptions, setSubscriptions] = useState({
    data: [],
    total: 0,
  });

  const userId = localStorage.getItem("userId");

  const getSubscriptions = async function (
    startDate,
    endDate,
    status,
    search,
    page
  ) {
    setLoading(true);
    setSubscriptions({
      data: [],
      total: 0,
    });
    let callResult = await makeGetApiCall(
      `storeSubscriptions?page=${page}&storeId=${userId}
      &startDate=${format(new Date(startDate), "yyyy-MM-dd")}
      &endDate=${format(new Date(endDate), "yyyy-MM-dd")}&search=${search}`
    );

    setLoading(false);
    setSlideOver({
      childComponent: null,
      open: false,
    });
    if (callResult.success) {
      setSubscriptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getFrequencies = async function () {
    let callResult = await makeGetApiCall(
      `allStoreFrequenciesForDropdown?storeId=${userId}`
    );

    setFrequencies(callResult.data);
  };

  const getFrequencyName = (type) => {
    var typeName = "";
    frequencies.forEach((element) => {
      if (element.value === type) {
        typeName = element.label;
      }
    });
    return typeName;
  };

  const updateAmountCall = async function (product, values, index) {
    setFormLoading(true);
    const nextDate = format(new Date(values.next_date), "yyyy-MM-dd");
    const payload = {
      amount: parseInt(values.amount),
      next_date: nextDate,
      deliveryDateId: parseInt(values.deliveryDateId),
      type: values.type,
    };
    let callResult = await makePostApiCall(
      `userSubscriptionUpdate/${product.id}`,
      payload
    );

    setFormLoading(false);
    setSlideOver({
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      // starts updating subscription here
      const currentSubscriptions = [...subscriptions.data];
      const updatedSubscription = {
        ...subscriptions.data[index],
        amount: values.amount,
        next_date: nextDate,
        type: values.type,
        frequency_name: getFrequencyName(values.type),
        frequency_value: values.type,
      };

      const updatePayload = {
        index: index,
        item: updatedSubscription,
      };

      const updatedSubscriptions = updateObjectInArray(
        currentSubscriptions,
        updatePayload
      );

      setSubscriptions({
        ...subscriptions,
        data: updatedSubscriptions,
      });
      // ends updating subscription here

      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createNewAddressApiCall = async function (
    values,
    deliveryDateId,
    customerId
  ) {
    values["customerId"] = customerId;
    values["deliveryDateId"] = deliveryDateId;
    let callResult = await makePostApiCall(
      "createNewAddressAndAssignToSubscription",
      values
    );

    setSlideOver({
      childComponent: null,
      open: false,
    });
    setEmptyModalOpen({
      open: false,
      childComponent: null,
    });

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
      getSubscriptions(
        filter.startDate,
        filter.endDate,
        filter.dropdownValue,
        filter.searchValue
      );
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const setAddressForSubscription = async function (addressId, deliveryDateId) {
    let callResult = await makePostApiCall("updateSubscriptionAddress", {
      addressId: addressId,
      deliveryDateId: deliveryDateId,
    });

    setEmptyModalOpen({
      open: false,
      childComponent: null,
    });
    setSlideOver({
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
      getSubscriptions(
        filter.startDate,
        filter.endDate,
        filter.dropdownValue,
        filter.searchValue
      );
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openCreateNewAddressForm = (deliveryDateId, customerId) => {
    setEmptyModalOpen({
      childComponent: (
        <CreateNewAddressForm
          createNewAddressApiCall={createNewAddressApiCall}
          customerId={customerId}
          deliveryDateId={deliveryDateId}
        />
      ),
      open: true,
    });
  };

  const openModalToChangeAddress = async function (deliveryDateId, customerId) {
    let callResult = await makeGetApiCall(`fetchAddresses/${customerId}`);

    setEmptyModalOpen({
      childComponent: (
        <AllAddressesForm
          addresses={callResult.data}
          setAddressForSubscription={setAddressForSubscription}
          openCreateNewAddressForm={openCreateNewAddressForm}
          deliveryDateId={deliveryDateId}
          customerId={customerId}
        />
      ),
      open: true,
    });
  };

  const changeSubscription = (product, index) => {
    setSlideOver({
      childComponent: (
        <ChangeSubscriptionForm
          frequencies={frequencies}
          index={index}
          openModalToChangeAddress={openModalToChangeAddress}
          product={product}
          loading={formLoading}
          setOpen={setSlideOver}
          updateAmountCall={updateAmountCall}
        />
      ),
      open: true,
    });
  };

  const updateFilter = (values) => {
    setFilter(values);
    getSubscriptions(
      values.startDate,
      values.endDate,
      null,
      values.searchValue
    );
  };

  const cancelSubscription = async function (product, index) {
    let callResult = await makePostApiCall(`cancelSubscription/${product.id}`);

    const updatedSubscriptions = subscriptions.data.filter(
      (prod) => prod.id !== product.id
    );

    setSubscriptions({
      ...subscriptions,
      data: updatedSubscriptions,
    });
    setConfirmationModal({
      action: null,
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openCancelSubscriptionModal = (product, index) => {
    setConfirmationModal({
      action: () => cancelSubscription(product, index),
      open: true,
      loading: false,
    });
  };

  const skipSubscription = async function (product, index) {
    let callResult = await makePostApiCall(`skipSubscription/${product.id}`);

    setSkipSubscriptionModal({
      action: null,
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      // starts updating subscription here
      const currentSubscriptions = [...subscriptions.data];
      const updatedSubscription = {
        ...subscriptions.data[index],
        delivery_status: "6",
      };

      const updatePayload = {
        index: index,
        item: updatedSubscription,
      };

      const updatedSubscriptions = updateObjectInArray(
        currentSubscriptions,
        updatePayload
      );

      setSubscriptions({
        ...subscriptions,
        data: updatedSubscriptions,
      });
      // ends updating subscription here
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openSkipSubscriptionModal = (product, index) => {
    setSkipSubscriptionModal({
      action: () => skipSubscription(product, index),
      open: true,
      loading: false,
    });
  };

  return {
    changeSubscription,
    confirmationModal,
    emptyModalOpen,
    filter,
    getFrequencies,
    getSubscriptions,
    loading,
    openCancelSubscriptionModal,
    openSkipSubscriptionModal,
    setConfirmationModal,
    setEmptyModalOpen,
    setShowSimpleNoti,
    setSlideOver,
    setSkipSubscriptionModal,
    skipSubscriptionModal,
    showSimpleNoti,
    slideOver,
    subscriptions,
    updateFilter,
  };
}
