import { useEffect } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";

import Select from "react-select";

const initialFValues = {
  firstname: "",
  lastname: "",
  street: "",
  city: "",
  region: "",
  country_id: "",
  postcode: "",
  telephone: "",
  is_default_shipping: 0,
  is_default_billing: 0,
};

const regions = [
  {
    value: 66,
    label: "Alberta",
  },
  {
    value: 67,
    label: "British Columbia",
  },
  {
    value: 68,
    label: "Manitoba",
  },
  {
    value: 70,
    label: "New Brunswick",
  },
  {
    value: 69,
    label: "Newfoundland and Labrador",
  },
  {
    value: 72,
    label: "Northwest Territories",
  },
  {
    value: 71,
    label: "Nova Scotia",
  },
  {
    value: 73,
    label: "Nunavut",
  },
  {
    value: 74,
    label: "Ontario",
  },
  {
    value: 75,
    label: "Prince Edward Island",
  },
  {
    value: 76,
    label: "Quebec",
  },
  {
    value: 77,
    label: "Saskatchewan",
  },
  {
    value: 78,
    label: "Yukon Territory",
  },
];

const countries = [
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "US",
    label: "United States",
  },
];

export default function CreateNewAddressForm(props) {
  const { createNewAddressApiCall, customerId, deliveryDateId } = props;

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    false
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    createNewAddressApiCall(values, deliveryDateId, customerId);
  };

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  return (
    <div>
      <div>
        <div className="text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Create new address
          </Dialog.Title>
          <div className="columns-2 mt-2">
            <Input
              label="First name"
              name="firstname"
              onChange={handleInputChange}
              value={values.firstname}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
            <Input
              label="Last name"
              name="lastname"
              onChange={handleInputChange}
              value={values.lastname}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="columns-2 mt-2">
            <Input
              label="Address 1"
              name="street1"
              onChange={handleInputChange}
              value={values.street1}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
            <Input
              label="Address 2"
              name="street2"
              onChange={handleInputChange}
              value={values.street2}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="columns-2 mt-5">
            <Input
              label="City"
              name="city"
              onChange={handleInputChange}
              value={values.city}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
            <div>
              <div className="flex space-x-2">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900"
                >
                  State/Province
                </label>
              </div>
              <div className="mt-1">
                <Select
                  name="region"
                  options={regions}
                  placeholder=""
                  noOptionsMessage={() => "Choose a State/Province"}
                  onChange={(val) => {
                    handleInputChange(
                      convertToDefEventPara("region", val.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="columns-2 mt-5">
            <Input
              label="Zip/Postal Code"
              name="postcode"
              onChange={handleInputChange}
              value={values.postcode}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
            <div>
              <div className="flex space-x-2">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900"
                >
                  Country
                </label>
              </div>
              <div className="mt-1">
                <Select
                  name="country_id"
                  options={countries}
                  placeholder=""
                  noOptionsMessage={() => "Choose a Country"}
                  onChange={(val) => {
                    handleInputChange(
                      convertToDefEventPara("country_id", val.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="columns-2 mt-2">
            <Input
              label="Telephone #"
              name="telephone"
              onChange={handleInputChange}
              value={values.telephone}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
            <div>
              {/* First checkbox */}
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="is_default_shipping"
                    aria-describedby="default-shipping-description"
                    name="is_default_shipping"
                    onChange={handleInputChange}
                    value={values.is_default_shipping}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    Make default shipping
                  </label>
                </div>
              </div>
              {/* Second checkbox */}
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="is_default_billing"
                    aria-describedby="default-billing-description"
                    name="is_default_billing"
                    onChange={handleInputChange}
                    value={values.is_default_billing}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    Make default billing
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 text-center">
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          Create new address
        </button>
      </div>
    </div>
  );
}
