import {
  ArchiveBoxIcon,
  ChartBarIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname) {
  var title;
  var routes;
  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Subscribers",
      href: "/subscribers",
      icon: UsersIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Subscriptions",
      href: "/subscriptions",
      icon: ArchiveBoxIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Frequency Mapper",
      href: "/frequency-mapper",
      icon: ChartBarIcon,
      current: false,
      showInMenu: true,
    },
  ];

  routes.forEach((route) => {
    if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
