import { useEffect } from "react";

import { connect } from "react-redux";

import { MyAccountViewModel } from "../../viewmodels/MyAccountViewModel";

import MyAccountForm from "../../components/forms/my-account/MyAccountForm";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

function MyAccount(props) {
  const { logout } = props;

  const { getStoreDetails, loading, saveDetails, storeDetails } =
    MyAccountViewModel(logout);

  useEffect(() => {
    getStoreDetails();
  }, []);

  return (
    <div>
      <div>
        <MyAccountForm
          loading={loading}
          saveDetails={saveDetails}
          storeDetails={storeDetails}
        />
      </div>
      {/* <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
      />
      <SimpleNotification
        notificationDetails={showSimpleNoti}
        setOpen={setShowSimpleNoti}
      />
      <SkipSubscriptionModal
        skipSubscriptionModal={skipSubscriptionModal}
        setSkipSubscriptionModal={setSkipSubscriptionModal}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      /> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyAccount)
);
