import { useEffect } from "react";
import { useParams } from "react-router";

import SimpleNotification from "../../components/notifications/SimpleNotification";
import SlideOver from "../../components/slideOver/SlideOver";
import { format } from "date-fns";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import EmptyModal from "../../components/modals/EmptyModal";
import SkipSubscriptionModal from "../../components/modals/SkipSubscriptionModal";
import { SubscriberDetailsViewModel } from "../../viewmodels/SubscriberDetailsViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

function SubscriberDetails(props) {
  const { logout } = props;

  const { id } = useParams();

  const {
    cancelSubscription,
    changeSubscription,
    confirmationModal,
    getFrequencies,
    getUserSubscriptions,
    getSubscriberInformation,
    subscriberInformation,
    formLoading,
    emptyModalOpen,
    loading,
    openCancelSubscriptionModal,
    openSkipSubscriptionModal,
    setConfirmationModal,
    setEmptyModalOpen,
    setShowSimpleNoti,
    setSkipSubscriptionModal,
    setSlideOver,
    showSimpleNoti,
    slideOver,
    skipSubscriptionModal,
    subscriptions,
  } = SubscriberDetailsViewModel(id, logout);

  useEffect(() => {
    getUserSubscriptions();
    getSubscriberInformation();
    getFrequencies();
  }, []);

  return (
    <div>
      <div className="mt-10">
        <div>
          <h3 className="text-3xl font-semibold leading-7 text-gray-900">
            Active subscriptions for: {subscriberInformation.name}
          </h3>
          <h3 className="text-2xl font-semibold leading-7 text-gray-900 mt-2">
            {subscriberInformation.email}
          </h3>
        </div>
        <div className="mt-5">
          <ul className="divide-y divide-gray-100">
            {loading
              ? "Loading"
              : subscriptions.map((product, index) => (
                  <li
                    key={product.id}
                    className="md:flex justify-between gap-x-6 py-5"
                  >
                    <div className="flex gap-x-4">
                      <img
                        className="h-12 w-12 flex-none"
                        src={product.image}
                        alt=""
                      />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          <a
                            href={
                              "https://fridge-filters.ca/" + product.zoeyUrl
                            }
                            className="hover:underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {product.name} ({product.frequency_name}) - $
                            {product.price}
                          </a>
                        </p>
                        <div className="mt-1 flex">
                          <div className="flex">
                            <p className="text-xs leading-5 text-gray-900">
                              Quantity:
                            </p>
                            <p className="ml-1 text-xs leading-5 text-gray-500">
                              {product.amount}
                            </p>
                          </div>
                          <div className="flex ml-5">
                            <p className="text-xs leading-5 text-gray-900">
                              Next shipment date:
                            </p>
                            <p className="ml-1 text-xs leading-5 text-gray-500">
                              {format(
                                new Date(product.next_date),
                                "LLL dd, YYY"
                              )}
                              {product.delivery_status === "6" ? (
                                <p className="text-red-600">Skipped</p>
                              ) : null}
                            </p>
                          </div>
                        </div>
                        <div className="mt-1">{product.original_order}</div>
                      </div>
                    </div>
                    <div className="block items-center gap-x-6 mt-5 md:mt-0">
                      <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => changeSubscription(product, index)}
                      >
                        Change subscription
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 ml-3"
                        onClick={() =>
                          openSkipSubscriptionModal(product, index)
                        }
                      >
                        Skip
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ml-3"
                        onClick={() =>
                          openCancelSubscriptionModal(product, index)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
      />
      <EmptyModal
        childComponent={emptyModalOpen.childComponent}
        open={emptyModalOpen.open}
        setOpen={setEmptyModalOpen}
      />
      <SimpleNotification
        notificationDetails={showSimpleNoti}
        setOpen={setShowSimpleNoti}
      />
      <SkipSubscriptionModal
        skipSubscriptionModal={skipSubscriptionModal}
        setSkipSubscriptionModal={setSkipSubscriptionModal}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriberDetails)
);
