import { useEffect } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";

import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertToDefEventPara } from "../../../shared/utility";

const initialFValues = {
  amount: "",
  next_date: "",
  deliveryDateId: "",
  type: "",
};

export default function ChangeSubscriptionForm(props) {
  const {
    frequencies,
    index,
    openModalToChangeAddress,
    product,
    setOpen,
    updateAmountCall,
  } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAmountCall(product, values, index);
  };

  useEffect(() => {
    setValues({
      amount: product.amount,
      next_date: new Date(product.next_date),
      deliveryDateId: product.deliveryDateId,
      type: product.type,
    });
  }, [product, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Update subscription
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-2">
              <Input
                label="Quantity"
                name="amount"
                onChange={handleInputChange}
                value={values.amount}
                labelOn={true}
                type="number"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="mt-3">
              <DatePicker
                selected={values.next_date}
                dateFormat="LLL dd, YYY"
                customInput={
                  <Input
                    label="Next shipment date"
                    name="next_date"
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                }
                onChange={(date) =>
                  handleInputChange({
                    target: {
                      name: "next_date",
                      value: date,
                    },
                  })
                }
              />
            </div>
            <div className="mt-3">
              <div className="flex space-x-2">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900"
                >
                  Frequency
                </label>
              </div>
              <div className="mt-1">
                <Select
                  name="type"
                  options={frequencies}
                  placeholder=""
                  defaultValue={
                    product
                      ? {
                          value: product.frequency_value,
                          label: product.frequency_name,
                        }
                      : []
                  }
                  noOptionsMessage={() => "Choose a frequency"}
                  onChange={(val) => {
                    handleInputChange(convertToDefEventPara("type", val.value));
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="flex space-x-2">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900"
                >
                  Shipping Address
                </label>
              </div>
              <div className="mt-1">{product.fullAddress}</div>
              <div className="mt-1">
                <button
                  type="button"
                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  onClick={() =>
                    openModalToChangeAddress(
                      product.deliveryDateId,
                      product.subscriber_zoey_id
                    )
                  }
                >
                  Change Address
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
