import { useState } from "react";

import { makeGetApiCall } from "../shared/makeApiCall";
import AllAddressesForm from "../components/forms/subscriptions/AllAddressesForm";
import CreateNewAddressForm from "../components/forms/subscriptions/CreateNewAddressForm";
import ChangeSubscriptionForm from "../components/forms/subscriptions/ChangeSubscriptionForm";
import { makePostApiCall } from "../shared/makeApiCall";

import { updateObjectInArray } from "../shared/utility";
import { SimpleNotificationType } from "../components/notifications/SimpleNotification";

import { format } from "date-fns";

export function SubscriberDetailsViewModel(customerId, logout) {
  const userId = localStorage.getItem("userId");

  const [confirmationModal, setConfirmationModal] = useState({
    action: null,
    open: false,
    childComponent: null,
  });
  const [emptyModalOpen, setEmptyModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [frequencies, setFrequencies] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [skipSubscriptionModal, setSkipSubscriptionModal] = useState({
    action: null,
    open: false,
    childComponent: null,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriberInformation, setSubscriberInformation] = useState({});

  const getUserSubscriptions = async function () {
    setLoading(true);
    let callResult = await makeGetApiCall(
      "userSubscriptions?userId=" + customerId
    );

    setLoading(false);

    if (callResult.success) {
      setSubscriptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getFrequencies = async function () {
    let callResult = await makeGetApiCall(
      `allStoreFrequenciesForDropdown?storeId=${userId}`
    );

    if (callResult.success) {
      setFrequencies(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getSubscriberInformation = async function () {
    let callResult = await makeGetApiCall(
      `getSubscriberInformation/${customerId}`
    );

    if (callResult.success) {
      setSubscriberInformation(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createNewAddressApiCall = async function (values, deliveryDateId) {
    values["customerId"] = customerId;
    values["deliveryDateId"] = deliveryDateId;
    let callResult = await makePostApiCall(
      "createNewAddressAndAssignToSubscription",
      values
    );
    setSlideOver({
      childComponent: null,
      open: false,
    });
    setEmptyModalOpen({
      open: false,
      childComponent: null,
    });

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
      getUserSubscriptions();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openCreateNewAddressForm = (deliveryDateId) => {
    setEmptyModalOpen({
      childComponent: (
        <CreateNewAddressForm
          createNewAddressApiCall={createNewAddressApiCall}
          deliveryDateId={deliveryDateId}
        />
      ),
      open: true,
    });
  };

  const setAddressForSubscription = async function (addressId, deliveryDateId) {
    let callResult = await makePostApiCall("updateSubscriptionAddress", {
      addressId: addressId,
      deliveryDateId: deliveryDateId,
    });

    setEmptyModalOpen({
      open: false,
      childComponent: null,
    });
    setSlideOver({
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
      getUserSubscriptions();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openModalToChangeAddress = async function (deliveryDateId) {
    let callResult = await makeGetApiCall(`fetchAddresses/${customerId}`);

    setEmptyModalOpen({
      childComponent: (
        <AllAddressesForm
          addresses={callResult.data}
          setAddressForSubscription={setAddressForSubscription}
          openCreateNewAddressForm={openCreateNewAddressForm}
          deliveryDateId={deliveryDateId}
        />
      ),
      open: true,
    });
  };

  const getFrequencyName = (type) => {
    var typeName = "";
    frequencies.forEach((element) => {
      if (element.value === type) {
        typeName = element.label;
      }
    });
    return typeName;
  };

  const updateAmountCall = async function (product, values, index) {
    setFormLoading(true);
    const nextDate = format(new Date(values.next_date), "yyyy-MM-dd");
    const payload = {
      amount: parseInt(values.amount),
      next_date: nextDate,
      deliveryDateId: parseInt(values.deliveryDateId),
      type: values.type,
    };
    let callResult = await makePostApiCall(
      `userSubscriptionUpdate/${product.id}`,
      payload
    );

    if (callResult.success) {
      // starts updating product here
      const currentSubscriptions = [...subscriptions];
      const updatedSubscription = {
        ...subscriptions[index],
        amount: values.amount,
        next_date: values.next_date,
        type: values.type,
        frequency_name: getFrequencyName(values.type),
        frequency_value: values.type,
      };
      const updatePayload = {
        index: index,
        item: updatedSubscription,
      };
      const updatedSubscriptions = updateObjectInArray(
        currentSubscriptions,
        updatePayload
      );
      setSubscriptions(updatedSubscriptions);
      // ends updating product here

      setFormLoading(false);
      setSlideOver({
        childComponent: null,
        open: false,
      });

      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const changeSubscription = (product, index) => {
    setSlideOver({
      childComponent: (
        <ChangeSubscriptionForm
          frequencies={frequencies}
          index={index}
          openModalToChangeAddress={openModalToChangeAddress}
          product={product}
          loading={formLoading}
          setOpen={setSlideOver}
          updateAmountCall={updateAmountCall}
        />
      ),
      open: true,
    });
  };

  const cancelSubscription = async function (product, index) {
    let callResult = await makePostApiCall(`cancelSubscription/${product.id}`);

    setConfirmationModal({
      action: null,
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      const updatedSubscriptions = subscriptions.filter(
        (prod) => prod.id !== product.id
      );

      setSubscriptions(updatedSubscriptions);
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openCancelSubscriptionModal = (product, index) => {
    setConfirmationModal({
      action: () => cancelSubscription(product, index),
      open: true,
    });
  };

  const skipSubscription = async function (product, index) {
    let callResult = await makePostApiCall(`skipSubscription/${product.id}`);

    setSkipSubscriptionModal({
      action: null,
      childComponent: null,
      open: false,
    });

    if (callResult.success) {
      // starts updating subscription here
      const currentSubscriptions = [...subscriptions];
      const updatedSubscription = {
        ...subscriptions[index],
        delivery_status: "6",
      };

      const updatePayload = {
        index: index,
        item: updatedSubscription,
      };

      const updatedSubscriptions = updateObjectInArray(
        currentSubscriptions,
        updatePayload
      );

      setSubscriptions({
        ...subscriptions,
        data: updatedSubscriptions,
      });
      // ends updating subscription here

      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openSkipSubscriptionModal = (product, index) => {
    setSkipSubscriptionModal({
      action: () => skipSubscription(product, index),
      open: true,
    });
  };

  return {
    cancelSubscription,
    changeSubscription,
    confirmationModal,
    emptyModalOpen,
    getFrequencies,
    getUserSubscriptions,
    formLoading,
    loading,
    openCancelSubscriptionModal,
    openSkipSubscriptionModal,
    setConfirmationModal,
    setEmptyModalOpen,
    setShowSimpleNoti,
    setSkipSubscriptionModal,
    setSlideOver,
    showSimpleNoti,
    slideOver,
    skipSubscriptionModal,
    subscriptions,
    getSubscriberInformation,
    subscriberInformation,
  };
}
