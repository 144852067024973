import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../shared/makeApiCall";
import FrequencyMapperForm from "../components/forms/frequency/FrequencyMapperForm";

export function FrequencyMapperViewModel(logout) {
  const [frequencies, setFrequencies] = useState({ data: [] });
  const [loading, setLoading] = useState(true);
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const userId = localStorage.getItem("userId");
  var page = 1;

  const getFrequencies = async function () {
    setLoading(true);
    let callResult = await makeGetApiCall(
      `storeFrequencies?page=${page}&storeId=${userId}`
    );

    setLoading(false);
    if (callResult.success) {
      setFrequencies(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const addNewFrequency = async function (values) {
    const payload = {
      name: values.name,
      zoeyAttribute: values.zoeyAttribute,
      frequency: values.frequency,
      store: userId,
    };
    let callResult = await makePostApiCall("addFrequency", payload);

    if (callResult.success) {
      getFrequencies();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateFrequency = async function (values, index) {
    const payload = {
      name: values.name,
      zoeyAttribute: values.zoeyAttribute,
      frequency: values.frequency,
    };
    let callResult = await makePostApiCall(
      `updateFrequency/${values.id}`,
      payload
    );

    if (callResult.success) {
      getFrequencies();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openSlideToAddFrequency = () => {
    setSlideOver({
      childComponent: (
        <FrequencyMapperForm
          actionCall={addNewFrequency}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const openSlideToEditFrequency = (frequency, index) => {
    setSlideOver({
      childComponent: (
        <FrequencyMapperForm
          actionCall={updateFrequency}
          frequency={frequency}
          index={index}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  return {
    frequencies,
    getFrequencies,
    openSlideToAddFrequency,
    openSlideToEditFrequency,
    setSlideOver,
    slideOver,
  };
}
