import * as actionTypes from "./actionTypes";

import { config } from "../../constants";

import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  localStorage.setItem("authToken", authData.token);
  localStorage.setItem("name", authData.user.name);
  localStorage.setItem("contactName", authData.user.contact_name);
  localStorage.setItem("email", authData.user.email);
  localStorage.setItem("website", authData.user.website);
  localStorage.setItem("subsUrl", authData.user.subs_url);
  localStorage.setItem("userId", authData.user.id);

  return {
    type: actionTypes.AUTH_SUCCESS,
    authToken: authData.token,
    name: authData.user.name,
    contactName: authData.user.contact_name,
    email: authData.user.email,
    website: authData.user.website,
    subsUrl: authData.user.subs_url,
    userId: authData.user.userId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const requestFailed = (error) => {
  return {
    type: actionTypes.REQUEST_FAILED,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("name");
  localStorage.removeItem("contactName");
  localStorage.removeItem("email");
  localStorage.removeItem("website");
  localStorage.removeItem("subsUrl");
  localStorage.removeItem("userId");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationDateout) => {
  //   return (dispatch) => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationDateout * 1000);
  //   };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const payload = {
      email: email,
      password: password,
    };
    let url = config.url.API_URL + "storeLogin";

    axios
      .post(url, payload)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );

        dispatch(authSuccess(response.data));
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      // } else {

      const authData = {
        token: localStorage.getItem("authToken"),
        user: {
          name: localStorage.getItem("name"),
          contact_name: localStorage.getItem("contactName"),
          email: localStorage.getItem("email"),
          website: localStorage.getItem("website"),
          subs_url: localStorage.getItem("subsUrl"),
          status: localStorage.getItem("status"),
          id: localStorage.getItem("userId"),
        },
      };

      dispatch(authSuccess(authData));
      // dispatch(
      //   checkAuthTimeout(
      //     (expirationDate.getTime() - new Date().getTime()) / 1000
      //   )
      // );
      // }
    }
  };
};
