import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  id: null,
  name: "",
  contactName: "",
  email: "",
  error: null,
  subsUrl: "",
  website: "",
  userId: null,
};

const authStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    authToken: action.authToken,
    error: null,
    fname: action.fname,
    userId: action.userId,
  });
};

const authFail = (state, action) => {
  if (action.error.data) {
    return updateObject(state, {
      error: {
        title: action.error.data.title,
        subtitle: action.error.data.subtitle,
      },
      loading: false,
    });
  }
  return updateObject(state, {
    error: {
      title: "",
      subtitle: "We could not complete your request, please try again.",
    },
    loading: false,
  });
};

const authLogout = (state) => {
  return updateObject(state, {
    authToken: null,
    fname: null,
    userId: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);

    default:
      return state;
  }
};

export default reducer;
