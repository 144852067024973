import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../shared/makeApiCall";

export function MyAccountViewModel(logout) {
  const [storeDetails, setStoreDetails] = useState({ data: [] });
  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem("userId");

  const getStoreDetails = async function () {
    setLoading(true);
    let callResult = await makeGetApiCall(`storeDetails/${userId}`);

    setLoading(false);

    if (callResult.success) {
      setStoreDetails(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const saveDetails = async function (values) {
    let callResult = await makePostApiCall(
      `updateStoreDetails/${userId}`,
      values
    );

    if (!callResult.success) {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    getStoreDetails,
    loading,
    saveDetails,
    storeDetails,
  };
}
