import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import SideNavigationBar from "../../components/sideNavigationBar/SideNavigationBar";
import Dashboard from "../../views/dashboard/Dashboard";
import Subscribers from "../../views/subscribers/Subscribers";
import Subscriptions from "../../views/subscriptions/Subscriptions";

import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import FrequencyMapper from "../../views/frequency-mapper/FrequencyMapper";
import SubscriberDetails from "../../views/subscribers/SubscriberDetails";

import { withRouter } from "../../shared/utility";
import MyAccount from "../../views/myAccount/MyAccount";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DefaultLayout(props) {
  const { logout } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const userNavigation = [
    {
      name: "My account",
      action: () => {
        window.location = "/my-account";
      },
    },
    { name: "Sign out", action: () => logout() },
  ];

  return (
    <div className="h-full">
      <div>
        <SideNavigationBar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form
                className="relative flex flex-1"
                action="#"
                method="GET"
              ></form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open my account</span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        My account
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                              onClick={item.action}
                            >
                              {item.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="">
            <div className="px-4 sm:px-6 lg:px-8">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/frequency-mapper" element={<FrequencyMapper />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/subscribers" element={<Subscribers />} />
                <Route
                  path="/subscribers/:id"
                  element={<SubscriberDetails />}
                />
                <Route path="/subscriptions" element={<Subscriptions />} />

                <Route
                  path="*"
                  element={<Navigate to="/subscriptions" replace />}
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
);
