import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useForm } from "../useForm";

import "react-datepicker/dist/react-datepicker.css";

const initialFValues = {
  endDate: "",
  startDate: "",
  status: "",
};

export default function SlideOver(props) {
  const { childComponent, slideOver, setSlideOver } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // getSubscriptions(values.startDate, values.endDate, values.status);
    // updateAmountCall(product, values, index);
  };

  return (
    <Transition.Root show={slideOver.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() =>
          setSlideOver({
            childComponent: null,
            open: false,
          })
        }
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  {childComponent}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
