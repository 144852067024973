import { useEffect, useRef, useState } from "react";
import { SubscribersViewModel } from "../../viewmodels/SubscribersViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";
import Pagination from "../../components/pagination/Pagination";

function Subscribers(props) {
  const { logout } = props;
  const { getSubscribers, loading, subscribers } = SubscribersViewModel(logout);

  useEffect(() => {
    getSubscribers(null, currentPage);
  }, []);

  const searchRef = useRef();

  const handleSearch = () => {
    getSubscribers(searchRef.current.value, currentPage);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
    getSubscribers(searchRef.current.value, newPage);
  };

  return (
    <div>
      <div className="mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              {/* search starts */}
              <div className="mt-1 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by email or last name"
                    ref={searchRef}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {/* search ends */}
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {subscribers.data.map((person) => (
                          <tr key={person.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.email}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href={`/subscribers/${person.id}`}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Subscriptions
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between bg-white px-4 py-3 mt-5 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">{subscribers.from}</span> to{" "}
                  <span className="font-medium">{subscribers.to}</span> of{" "}
                  <span className="font-medium">{subscribers.total}</span>{" "}
                  results
                </p>
              </div>

              <div className="ml-5">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={subscribers.total}
                  pageSize={20}
                  onPageChange={(page) => handlePagination(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscribers)
);
