import { useEffect } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";

import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { addDays } from "date-fns";

import { convertToDefEventPara } from "../../../shared/utility";

const dateOptions = [
  {
    label: "1 week",
    value: 0,
  },
  {
    label: "2 weeks",
    value: 1,
  },
  {
    label: "1 month",
    value: 2,
  },
  {
    label: "3 months",
    value: 3,
  },
  {
    label: "6 months",
    value: 4,
  },
  {
    label: "12 months",
    value: 5,
  },
  {
    label: "Custom",
    value: 6,
  },
];

const initialFValues = {
  startDate: "",
  endDate: "",
  dropdownValue: 0,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FilterSubscriptionsForm(props) {
  const { filter, searchValue, setOpen, updateFilter } = props;

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    false
  );

  const convertEndDateFromDropdown = (dropdownOption, endDate) => {
    var newDate = null;
    switch (dropdownOption) {
      case 0:
        newDate = addDays(new Date(), 7);
        break;
      case 1:
        newDate = addDays(new Date(), 14);
        break;
      case 2:
        newDate = addDays(new Date(), 30);
        break;
      case 3:
        newDate = addDays(new Date(), 90);
        break;
      case 4:
        newDate = addDays(new Date(), 180);
        break;
      case 5:
        newDate = addDays(new Date(), 365);
        break;
      default:
        newDate = endDate;
    }
    return newDate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newValues = {
      ...values,
      startDate: values.startDate,
      endDate: convertEndDateFromDropdown(values.dropdownValue, values.endDate),
      searchValue: searchValue,
    };
    updateFilter(newValues);
  };

  useEffect(() => {
    setValues({
      startDate: filter.startDate,
      endDate: filter.endDate,
      dropdownValue: filter.dropdownValue,
    });
  }, [filter, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Filter
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-3">
              <div className="flex space-x-2">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900"
                >
                  Date range
                </label>
              </div>
              <div className="mt-1">
                <Select
                  name="date"
                  options={dateOptions}
                  placeholder=""
                  defaultValue={dateOptions[filter.dropdownValue]}
                  noOptionsMessage={() => "Choose a date range"}
                  onChange={(val) => {
                    handleInputChange(
                      convertToDefEventPara("dropdownValue", val.value)
                    );
                  }}
                />{" "}
              </div>
            </div>
            <div
              className={classNames(
                values.dropdownValue === 6 ? "" : "hidden",
                "mt-3"
              )}
            >
              <DatePicker
                selected={values.startDate}
                dateFormat="LLL dd, YYY"
                customInput={
                  <Input
                    label="Start date"
                    name="startDate"
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                }
                onChange={(date) =>
                  handleInputChange({
                    target: {
                      name: "startDate",
                      value: date,
                    },
                  })
                }
              />
            </div>
            <div
              className={classNames(
                values.dropdownValue === 6 ? "" : "hidden",
                "mt-3"
              )}
            >
              <DatePicker
                selected={values.endDate}
                dateFormat="LLL dd, YYY"
                customInput={
                  <Input
                    label="End date"
                    name="endDate"
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                }
                onChange={(date) =>
                  handleInputChange({
                    target: {
                      name: "endDate",
                      value: date,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
