import { useState } from "react";

import { makeGetApiCall } from "../shared/makeApiCall";

export function SubscribersViewModel(logout) {
  const [loading, setLoading] = useState(true);
  const [subscribers, setSubscribers] = useState({
    data: [],
    total: 0,
  });

  const userId = localStorage.getItem("userId");

  const getSubscribers = async function (search, page) {
    setLoading(true);
    let callResult = await makeGetApiCall(
      `storeSubscribers?page=${page}&storeId=${userId}&search=${search}`
    );

    setLoading(false);
    if (callResult.success) {
      setSubscribers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return { getSubscribers, loading, subscribers };
}
